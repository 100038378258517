import _aol2 from "./aol";
import _hotmail2 from "./hotmail";
import _live2 from "./live";
import _outlook2 from "./outlook";
import _microsoft2 from "./microsoft";
import _more2 from "./more";
import _top2 from "./top100";
import _yahoo2 from "./yahoo";
import _all2 from "./all";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "all", {
  enumerable: true,
  get: function get() {
    return _all.all;
  }
});
Object.defineProperty(exports, "aol", {
  enumerable: true,
  get: function get() {
    return _aol.aol;
  }
});
Object.defineProperty(exports, "hotmail", {
  enumerable: true,
  get: function get() {
    return _hotmail.hotmail;
  }
});
Object.defineProperty(exports, "live", {
  enumerable: true,
  get: function get() {
    return _live.live;
  }
});
Object.defineProperty(exports, "microsoft", {
  enumerable: true,
  get: function get() {
    return _microsoft.microsoft;
  }
});
Object.defineProperty(exports, "more", {
  enumerable: true,
  get: function get() {
    return _more.more;
  }
});
Object.defineProperty(exports, "outlook", {
  enumerable: true,
  get: function get() {
    return _outlook.outlook;
  }
});
Object.defineProperty(exports, "top100", {
  enumerable: true,
  get: function get() {
    return _top.top100;
  }
});
Object.defineProperty(exports, "yahoo", {
  enumerable: true,
  get: function get() {
    return _yahoo.yahoo;
  }
});
var _aol = _aol2;
var _hotmail = _hotmail2;
var _live = _live2;
var _outlook = _outlook2;
var _microsoft = _microsoft2;
var _more = _more2;
var _top = _top2;
var _yahoo = _yahoo2;
var _all = _all2;
export default exports;