var exports = {};
!function (e, n) {
  for (var r in n) e[r] = n[r];
}(exports, function (e) {
  var n = {};
  function r(t) {
    if (n[t]) return n[t].exports;
    var o = n[t] = {
      i: t,
      l: !1,
      exports: {}
    };
    return e[t].call(o.exports, o, o.exports, r), o.l = !0, o.exports;
  }
  return r.m = e, r.c = n, r.d = function (e, n, t) {
    r.o(e, n) || Object.defineProperty(e, n, {
      enumerable: !0,
      get: t
    });
  }, r.r = function (e) {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  }, r.t = function (e, n) {
    if (1 & n && (e = r(e)), 8 & n) return e;
    if (4 & n && "object" == typeof e && e && e.__esModule) return e;
    var t = Object.create(null);
    if (r.r(t), Object.defineProperty(t, "default", {
      enumerable: !0,
      value: e
    }), 2 & n && "string" != typeof e) for (var o in e) r.d(t, o, function (n) {
      return e[n];
    }.bind(null, o));
    return t;
  }, r.n = function (e) {
    var n = e && e.__esModule ? function () {
      return e.default;
    } : function () {
      return e;
    };
    return r.d(n, "a", n), n;
  }, r.o = function (e, n) {
    return Object.prototype.hasOwnProperty.call(e, n);
  }, r.p = "", r(r.s = 0);
}([function (e, n, r) {
  "use strict";

  r.r(n), r.d(n, "emailMisspelled", function () {
    return d;
  });
  var t = function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "";
      return 1 === e.replace(/[^@]/g, "").length;
    },
    o = function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "";
      return e.includes("@") ? e.replace(/.*@/, "") : "";
    },
    i = function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "",
        n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1;
      return function (r) {
        var t = e.length - r.length;
        return t <= n && t >= -n;
      };
    },
    l = function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "",
        n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 1;
      return function (r) {
        for (var t = Array(e.length + 1).fill(null).map(function () {
            return Array(r.suggest.length + 1).fill(null);
          }), o = 0; o <= r.suggest.length; o += 1) t[0][o] = o;
        for (var i = 0; i <= e.length; i += 1) t[i][0] = i;
        for (var l = 1; l <= e.length; l += 1) for (var u = 1; u <= r.suggest.length; u += 1) {
          var a = r.suggest[u - 1] === e[l - 1] ? 0 : 1;
          t[l][u] = Math.min(t[l][u - 1] + 1, t[l - 1][u] + 1, t[l - 1][u - 1] + a);
        }
        return r.misspelledCount = t[e.length][r.suggest.length], r.misspelledCount <= n;
      };
    },
    u = function (e) {
      return {
        suggest: e,
        corrected: "",
        original: "",
        misspelledCount: 0
      };
    },
    a = function (e) {
      return function (n) {
        return e && n.suggest ? (n.corrected = e.replace(/@.*$/, "@".concat(n.suggest)), n.original = e, n) : n;
      };
    },
    s = function (e, n) {
      return e.misspelledCount - n.misspelledCount;
    },
    f = {
      lengthDiffMax: 3,
      maxMisspelled: 2,
      domains: []
    },
    c = [],
    d = function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : f,
        n = e.lengthDiffMax,
        r = void 0 === n ? 3 : n,
        d = e.maxMisspelled,
        g = void 0 === d ? 2 : d,
        p = e.domains,
        v = void 0 === p ? [] : p;
      if (!v || !Array.isArray(v) || !v.length) throw new Error("Please provide a domain list (for example: https://github.com/sl-julienamblard/email-misspelled/tree/master/src/domains)");
      return function (e) {
        if (!e || !t(e)) return c;
        var n = o(e);
        if (v.includes(n)) return c;
        var f = i(n, r),
          d = l(n, g),
          p = a(e);
        return v.filter(f).map(u).filter(d).map(p).sort(s);
      };
    };
  n.default = d;
}]));
export default exports;