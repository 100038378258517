import _domains2 from "./domains";
import _emailMisspelled2 from "./emailMisspelled";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {};
exports["default"] = void 0;
var _domains = _domains2;
Object.keys(_domains).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _domains[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _domains[key];
    }
  });
});
var _emailMisspelled = _emailMisspelled2;
var _default = _emailMisspelled.emailMisspelled;
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule,
  all = exports.all,
  aol = exports.aol,
  hotmail = exports.hotmail,
  live = exports.live,
  microsoft = exports.microsoft,
  more = exports.more,
  outlook = exports.outlook,
  top100 = exports.top100,
  yahoo = exports.yahoo;